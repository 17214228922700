<template>
  <header class="py-2 mb-1 position-relative" style="z-index:3;">    
    <b-container>      
      <!--<div class="d-inline-block logo-wrap pl-0 ml-2" >
        <img alt="לוגו" src="./../assets/logo.jpg">
      </div>-->
      <b-row class="align-items-md-center">
        <b-col class="col-10 col-md-8 col-lg-5">
        <div class="d-inline-block tagline">
          <div class="site-title">חיזוי תוצאות אמת</div>
          <div class="d-block d-xl-none pt-1">עודכן לאחרונה ב: {{lastUpdate}}</div>
           <b-btn 
            href="https://www.facebook.com/dictatools/posts/776355102993376"        
            target="_blank"
            style="background-color: #ff8a00;"
            class="d-block d-lg-none text-dark mt-1 px-1 border-0">
             <i class="fas fa-comments"></i>
            הצטרפו לדיון החם בפייסבוק 
          </b-btn>
        </div>
        </b-col>
        <b-col class="text-left">
          <b-btn
            href="https://www.facebook.com/dictatools/posts/776355102993376"
            target="_blank"
            class="py-1 d-none d-lg-inline-block shadow-none ml-2 text-dark"
            style="background-color: #ff8a00;"
          >
            <i class="fas fa-comments"></i>
            הצטרפו לדיון החם בפייסבוק    
          </b-btn>        
          <b-btn
            variant="outline-dark"
            class="py-1 d-none d-md-inline-block shadow-none"
            @click="showAbout=true"
          >
            <span>על אודות המיזם</span>            
          </b-btn>
          <span @click="showAbout=true" class="d-block d-md-none"><i class="fas fa-info-circle"></i></span>
          <about-dialog v-model="showAbout"></about-dialog>                    
          <min-votes-dialog v-model="showMinVotes"></min-votes-dialog>   
        </b-col>
      </b-row>      
    </b-container>
  </header>
</template>
<script>
import AboutDialog from '@/components/HowDialog'
import MinVotesDialog from '@/components/MinVotesDialog'
export default {
  name: 'Header',
  components: {
    AboutDialog,
    MinVotesDialog
  },
  data() {
    return {
      showAbout: false,
      showMinVotes: false
    }
  },    
  props: ['lastUpdate'],  
  mounted () {
    
  }  
}
</script>
<style scoped lang="scss">
  header {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.34);
    div {
      vertical-align: middle;      
      @media screen and (min-width: 992px) {
        line-height: 34px;
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        line-height: 17px;
      }      
    }
   }  
  .logo-wrap {
    img {
      width: 40px;
      height: 40px;
    }
  }
  .tagline {
    @media screen and (max-width: 767px) {
      font-size: 16px;  
    }  
    line-height: 18px;
    .site-title {
       font-size: 22px;
       line-height: 22px;
    }
  }
</style>  
