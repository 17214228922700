<template>
  <b-modal
    id="how-modal"
    v-model="show" 
    ok-only 
    centered 
    size="lg" 
    body-class="text-right"
    footer-class="border-top d-none d-sm-block text-right"
    header-class="d-none d-sm-block text-right"
    :ok-title="hebrew ? 'סגור' : 'Close'"
    ok-variant="secondary"
    >
    <template slot="modal-header">{{hebrew ? 'על אודות המיזם' : 'How it works'}}</template>
    <div class="mobile-modal-top position-fixed w-100 d-block d-sm-none">
      <div class="row">
        <div class="col-8">
          <h3>{{hebrew ? 'על אודות המיזם' : 'How it works'}}</h3>
        </div>
        <div @click="show=false" class="close-icon col-4" :class="{'text-left': hebrew, 'text-right': !hebrew}">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>    
    <div class="how-to-text" >
      <p>בשורה לחובבי ז׳אנר הפוליטי-סטטיסטי</p>
      <p>רוצים לדעת את תוצאות הבחירות לפני כולם? בליל הבחירות נפעיל אתר ייעודי המתבסס על אלגוריתמי למידת מכונה כדי לתת חיזויים מדויקים על בסיס תוצאות אמת ראשוניות. תמצאו שם את כל התוצאות הרשמיות מתעדכנות בזמן אמת, יחד עם חיזויים המתעדכנים בהתאם. יהיה מלא וגדוש במספרים מעניינים, וכמובן בלי קשקשת.  elections24.dicta.org.il</p>
      <p>אסביר איך זה עובד.</p>
      <p>איך אפשר לשער בצורה מדויקת את התוצאות הסופיות של הבחירות על בסיס דיווחים מ-2% מהקלפיות? התשובה הפשוטה, והלא נכונה, היא להניח שקלפיות אלה מספיק מייצגות את שאר הקלפיות ושההתפלגות הסופית של הקולות בין המפלגות תהיה כפי שמשתקפת מקלפיות אלה. זה סביר, אבל אפשר לעשות משהו הרבה יותר מתוחכם. בוא נראה איך.</p>
      <p>יש בארץ יותר מ-12,000 קלפיות. נניח שיש תוצאות אמת מ-200 מהן. עבור 200 אלה יש לנו, בנוסף לתוצאות הטריות, גם את התוצאות מהבחירות הקודמות (הכנסת ה-23). עכשיו, אם אוכל למצוא פונקציה שמעתיקה את התוצאות הקודמות לתוצאות החדשות בצורה טובה (כלומר, לכל אחת מהקלפיות, אם אפעיל את הטרנספורמציה על התוצאות הישנות אקבל קירוב טוב לתוצאות החדשות), אזי אוכל להפעיל אותה טרנספורמציה על שאר הקלפיות ו(בשאיפה) לקבל קירוב סביר של התוצאות הכלליות. </p>      
      <p>במקרה, יש כלים יפים בלמידת מכונה בדיוק למטרה זו (פרטים למטה למתעניינים). אבל איך אפשר לדעת אם זה באמת עובד? יישמנו את השיטה על הבחירות הקודמות. כלומר, לקחנו באקראי תוצאות אמת מ-2% מהקלפיות בבחירות לכנסת ה-23 ולמדנו על בסיסן טרנספורמציה מתוצאות הבחירות לכנסת ה-22 לאלו מ-23. על בסיס אותה טרנספורמציה חישבנו את התוצאות הכלליות בבחירות 23 בדיוק מזהיר.</p>
      <p>לשיטה הזאת יש המון יתרונות:</p>
      <ul>
        <li>יותר מדויק מהכללה סתמית של תוצאות חלקיות</li>
        <li>אפשר לחשב עבור כל יישוב בנפרד התוצאות הצפויות (מעבר לכך שזה מעניין לסקרנים חסרי סבלנות, אפשר כך לתפוס קלפיות מרמות)</li>
        <li>אפשר לחשב איך מצביעי מפלגה x בבחירות הקודמות הצביעו בבחירות הנוכחיות. (לדוגמה, מצביעי כחלון בבחירות 21 התפלגו כך בבחירות 22: 43% כחו״ל, 23% ליכוד, 15% ליברמן, 12% עבודה-גשר.) הפעם יהיה מעניין, למשל, לדעת למי הצביעו אלה שהצביעו כחו״ל בסיבוב הקודם.</li>        
      </ul>
      <p>כמה פרטים לחנונים:</p>
      <ul>
        <li>הלמידה היא באמצעות gradient descent עם backpropagation רגיל. בסוף התברר שאין אפילו צורך בשכבות; מטריצה פשוטה מספיק טוב.</li>
        <li>יש כל מיני בעיות קטנות הצפויות לשבש את הלמידה. יש קלפיות חדשות, מן הסתם תהיה הרחבה של תופעת הצבעת ״נכים״ בקלפיות אחרות, וכו׳. תופעות כאלו היו גם בעבר ולא שיבשו יותר מדי.</li>
        <li>מקווים שהתוצאות הרשמיות יעלו לרשת בפורמט קל לשימוש. קשה לדעת מה יהיה.</li>
        <li>אחרי שמחשבים את מספר הקולות הצפויים לכל מפלגה, אנחנו מורידים את אלה שלא עברו את אחוז החסימה ומחלקים מנדטים לפי שיטת בדר-עופר, כמו שצריך.</li>
        <li>שימו לב: הערוצים המסחריים מעלים חיזויים עם סגירת הקלפי בשעה 10:00 על בסיס מדגם. אנחנו לא עושים מדגם ולא נקדים את הערוצים. אנחנו נתחיל להעלות חיזויים רק אחרי שתוצאות אמת יתחילו לטפטף. נפרסם תוצאות משוערות לכל קלפי ועיר – וכמובן תוצאות כלליות – מהרגע שתדווחנה תוצאות מלפחות 2% מהקלפיות, ונמשיך לשפר את המודלים ככל שתהיינה יותר תוצאות אמת.</li>
        <li>הקרדיט על בניית כל המערכת במהירות שיא לשאלתיאל שמידמן המדהים.</li>
      </ul>
      <p>שורה תחתונה: מי שרוצה להנות מכל הטוב הזה מוזמן להצטרף אלינו באתר דיקטה. (ברגיל, דיקטה עוסקת ביישום אלגוריתמים מבלשנות חישובית על טקסטים עבריים ורבניים, אבל עשינו הסבה זמנית מאוד לרגל הבחירות הבעל״ט.)</p>
    </div>
    
  </b-modal>
</template>
<script>
export default {
  name: "about-dialog",
  props: ["value"],
  data() {
    return {
    }
  },
  computed: {
    hebrew () {
      return true
    }, 
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
}
</script>
<style lang="scss">
  #how-modal{    
    .modal-header {
      background-color: #e3e3e3;
      padding: 8px 16px;     
    }
    .modal-footer {
      padding: 16px;
      .btn-secondary {
        color: #212529;
        background-color: #e3e3e3;
        border-color: #e3e3e3;
        padding: 3.5px 18px;
        font-size: 14px;
        box-shadow: none;
      }  
    }      
  }
  @media screen and (max-width: 767px) {
    #how-modal{
      -webkit-overflow-scrolling: touch;
      .modal-dialog {
        margin: 0!important;
      }
      .modal-content {
        border-radius: 0!important;
        height: 100vh;
        overflow-y: scroll;
        overflow-x:hidden;
        background:white;
      }
      .mobile-modal-top{
        height: 50px;
        background-color: #e3e3e3;
        top:0;
        right:0;
        padding: 13px 15px 0 15px;
        z-index: 1;
        h3{
          text-decoration:none;
          font-size:18px;
        }
      }
      .how-to-text{
        padding-top:50px;
        max-height: 88vh;
        overflow-y: scroll;
        overflow-x:hidden;
        max-height: 70vh;
        overflow: auto;
        h3{
          text-decoration: underline;
        }
      }      
      @media screen and (max-width: 667px) {
        .how-to-text{
          max-height: 83vh;
        }
      }      
    }
  }
</style>
