<template>
  <b-modal
    id="min-votes-modal"
    v-model="show" 
    centered 
    size="lg" 
    body-class="text-right"
    hide-footer
    header-class="text-right py-2"
    >
   
    <div class="text-center py-2">
      התוצאות יוצגו באתר
      <br>
      מייד עם פרסום התוצאות של 2% מהקלפיות.
    </div>
    
  </b-modal>
</template>
<script>
export default {
  name: "min-votes-dialog",
  props: ["value"],
  data() {
    return {
    }
  },
  computed: {
    hebrew () {
      return true
    }, 
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
}
</script>
<style>
  #min-votes-modal .modal-header {
    background: #e3e3e3;
  }
</style>
