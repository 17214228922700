 <template>
  <div class="typeahead" id="city-typeahead" :class="customClass">    
    <div class="typeahead-wrap position-relative" ref="taWrap">
      <div 
        class="spinner-wrap position-absolute" 
        v-if="loading">
        <Spinner></Spinner>
      </div>
      <b-form-group 
        id="city-search"
        :label="label"
        :label-for="searchId"
        label-class="text-white mt-0 mb-3"
        label-size="md"
        class="mb-3 mb-lg-0 position-relative">
        <b-form-input 
          v-model="cityName"
          :id="searchId"
          :placeholder="placeholder"
          name="city-input"
          class="d-inline f-narkis py-0"          
          autocomplete="off"          
          @focus="inputFocused()"
          @focusout="inputFocusedOut()"
          @click="inputClicked()"
          @keydown.esc="showTypeaheads=false"
          @input="runTypeAhead()"
          @keydown="onKeyDownDropdown()"
          @keydown.down.prevent="arrowPress('down')"
          @keydown.up.prevent="arrowPress('up')"         
          @keyup.enter="runSearchFromDropDown()"          
        >
        </b-form-input>
        
        <i class="fas fa-caret-down position-absolute"
          style="cursor:pointer;left: 0;padding: 16px 200px 16px 18px;;"  
          @click="toggleAllCities">
        </i>
       
      </b-form-group>
      <div class="py-1 mb-n3 not-in-list text-white position-relative" v-if="showNotInList">{{hebrew ? 'המיקום לא נמצא ברשימה' : 'Word not in list'}}</div>
      <ul 
        id="search-typeaheads"
        ref="searchTypeaheads"
        tabindex="0"
        v-show="typeAheads && typeAheads.length > 0 && showTypeaheads" 
        class="autocomplete-results position-absolute rounded bg-white px-0">
        <li v-for="(word, i) in typeAheads" 
          tabindex="0"
          ref="listItem"
          :key="i" 
          @click="setInput(word, i)"
          @keyup.tab.prevent="setTabbedFocusedItem(i)"
          @keyup.enter="runSearchFromDropDown()"
          :class="{ 'is-active': i === activeIndex }"
          class="autocomplete-result px-2 pb-1 f-narkis position-relative"
        >
          {{ word.name }}
        </li>
      </ul>
    </div>  
  </div>
</template>
<script>
  let previousRequestCancellation = null
      // this variable holds a function that allows us to cancel the last request that we made
      // if it's null, there aren't any old requests
      // otherwise, it's a function
      // we call the function, and then the old request will no longer be able to call 'updateList'
      // 'updateList' is the name of our function that actually sets the Vue variable that holds the list of options

  const hebrewKeyboard = {'q': '\u002F',  'w': '\u0027', 
   'e': '\u05E7', 'r': '\u05E8', 't': '\u05D0',  
   'y': '\u05D8', 'u': '\u05D5', 'i': '\u05DF',  
   'o': '\u05DD', 'p': '\u05E4', 'a': '\u05E9',
   's': '\u05D3', 'd': '\u05D2', 'f': '\u05DB',
   'g': '\u05E2', 'h': '\u05D9', 'j': '\u05D7',
   'k': '\u05DC', 'l': '\u05DA', ';': '\u05E3',
   'z': '\u05D6', 'x': '\u05E1', 'c': '\u05D1',
   'v': '\u05D4', 'b': '\u05E0', 'n': '\u05DE',
   'm': '\u05E6', ',': '\u05EA', '.': '\u05E5'
  }
  import Spinner from '@/components/spinner'  
  export default {
    name: 'TypeAhead',
    props: ['typeAheadFunc', 'allCities', 'searchTerm', 'forceHebrew', 'searchId', 'customClass', 'clearAfterSet', 'placeholder', 'label'],
    components: {
      Spinner
    },
    data() {
      return {
        cityName: '',
        searchWord: '',
        typeAheads: [],
        showTypeaheads: false,
        activeIndex: 0,        
        showNotInList: false,
        loading: false
      }
    },
    mounted () {
      if (this.searchTerm !== '')
        this.cityName = this.searchTerm
      document.addEventListener('click', this.hideList)
     /*  this.$nextTick(function () { 
        document.getElementById(this.searchId).focus()
      }) */
    },
    beforeDestroy () {
     document.removeEventListener('click', this.hideList)
    },   
    methods: {
      inputFocused () {
        this.$emit('input-focused')
      },
      inputFocusedOut () {
        this.$emit('input-unfocused')
      },
      inputClicked () {   
        if (this.searchWord !== '' && !this.showNotInList) {
          //this.getUpdatedList(this.searchWord)
          this.showTypeaheads = true
        } else {
          this.typeAheads = []          
        }
      },
      toggleAllCities() {
        if (!this.showTypeaheads)
          this.updateList(this.allCities())
        else
          this.showTypeaheads = false  
      },
      // this function is a wrapper for the function the user supplies as a prop
      // it only adds the ability to cancel the effects of the call
      // (the call itself will continue, of course)
      getUpdatedList(tip) {
        // flag, used to check if a cancellation has been requested
        // note that there's a new copy of cancelled each time we call getUpdatedList()
        // so setting it to true from a previous call doesn't affect the current call
        let cancelled = false                
        this.loading = true
        // call the user's function. It must return a promise, and the promise must resolve to a list of words
        // that we can display in our list
        const promise = this.typeAheadFunc(tip)
        // wait for the user's function to give us a list, but then check `cancelled` before doing anything with it
        // if the function errors, do nothing
        promise.then(list => {
          if (!cancelled)             
            this.updateList(list)}).catch(()=>{
              this.loading=false
            })
          return () => cancelled = true
      },
      updateList (list) {
        this.loading = false
        this.typeAheads = list
        this.showNotInList = false
        if (list.length > 0 && Array.isArray(list)) {
          
          this.activeIndex = list.indexOf(this.searchWord) > 0 ? list.indexOf(this.searchWord) : 0
          this.showTypeaheads = true
         
          this.$nextTick(function () {
            this.$refs.searchTypeaheads.scrollTop = this.activeIndex * this.$refs.listItem[this.activeIndex].offsetHeight
          })  
        }  
        else {
          this.showTypeaheads = false  
          if (this.cityName !== '') {
            this.showNotInList = true
            this.$emit('not-in-list')
            /* this.$nextTick(function () {
              document.getElementById(this.searchId).focus()
            }) */  
          }
        } 
      },
      arrowPress (dir) { //navigate typeahead results with keys        
        if (dir == 'down') {
          if (this.activeIndex < this.typeAheads.length - 1){
            this.activeIndex++
            if (this.$refs.listItem[this.activeIndex].offsetTop >= 260) //scroll down when near bottom of list       
              this.$refs.searchTypeaheads.scrollTop = this.$refs.listItem[this.activeIndex].offsetTop - 290
          }
          else {
            this.activeIndex = 0
            this.$refs.searchTypeaheads.scrollTop = 0
          }  
        } else {
          if (this.activeIndex > 0) {
           this.activeIndex--
           if (this.$refs.listItem[this.activeIndex].offsetTop >= 260) //scroll up when near top of list
            this.$refs.searchTypeaheads.scrollTop = this.$refs.listItem[this.activeIndex].offsetTop - 250
          else 
            this.$refs.searchTypeaheads.scrollTop = this.$refs.listItem[this.activeIndex].offsetTop - 290
          } 
          else {
            this.activeIndex = this.typeAheads.length - 1
            this.$refs.searchTypeaheads.scrollTop = this.typeAheads.length * 37
          }  
        }
      },
      setTabbedFocusedItem (index) {
        this.activeIndex = index        
      },      
      hideList (event) {
        var container = document.getElementById('city-typeahead')
        if (container !== event.target && !container.contains(event.target)) {    
          this.showTypeaheads = false
        }
      },
      searchClicked () {
        if (this.searchWord !== '') { //search button clicked
          this.$emit('run-search-clicked', {searchWord: this.searchWord, onList: !this.showNotInList})
        }  
      },
      runSearchFromDropDown () {             
        if (this.showTypeaheads) { //enter pressed when dropdown open
          this.searchWord = this.typeAheads[this.activeIndex]
          this.cityName = this.searchWord.name
          this.$emit('run-search', this.searchWord) //emit call to tool's search api
          this.showTypeaheads = false
        } else {
          this.searchClicked () //search anyway
        }
      },      
      setInput (word, index) { //when a word from list is clicked on       
        this.searchWord = word
        this.cityName = this.searchWord.name
        this.showTypeaheads = false
        this.activeIndex = index
        this.$emit('run-search', this.searchWord)//emit call to tool's search api
        if (this.clearAfterSet) //after word selected don't show list on focus
          this.searchWord = ''
      },           
      onKeyDownDropdown () {
        if (this.forceHebrew)  
          this.englishKeyEventToHebrew(event, this.searchId)            
      }, 
      runTypeAhead () {  
        // this is the function that is called by the typeahead when it wants more words. Probably, the user typed a key.
        if (previousRequestCancellation)
          previousRequestCancellation()
        // call the actual function to get results, but save the cancellation function that it returns
        if(this.cityName !== '') {
          previousRequestCancellation = this.getUpdatedList(this.cityName)
        } else {
          this.typeAheads = []
          this.loading = false
          this.$emit('clear-search')
        }  
      },
      englishKeyEventToHebrew (keyEvt, input) {
        input = document.getElementById(input)
        // if this is a regular English key that could be a Hebrew letter
        if (!(keyEvt.metaKey || keyEvt.shiftKey || keyEvt.ctrlKey || keyEvt.altKey) && /^[A-Za-z;,.]$/.test(keyEvt.key)) {
          keyEvt.preventDefault()
          // someone may be typing in the middle of the input box
          const startpos = input.selectionStart || 0
          const endpos = input.selectionEnd || startpos
          // replace the selection (in the case of a cursor, it's the same as a selection of 0 characters)
          input.value = input.value.substring(0, startpos) + hebrewKeyboard[keyEvt.key.toLowerCase()] + input.value.substring(endpos)
          // put the cursor back where the user is expecting it
          input.setSelectionRange(startpos + 1, startpos + 1)
          // trigger an input event so Vue will update v-model
          input.dispatchEvent(new Event('input', { bubbles: true }))
          // return true in case the caller wants to test to see if this function handled the key
          return true
        }
       return false
      }      
    },
    computed: {     
      hebrew () {
        return true
      }   
    },
    watch: { //If url had search parameter update search input
      searchTerm () {
        this.cityName = this.searchTerm
      }        
    }       
  }
</script>
<style scoped lang="scss">
  @media (max-width: 991px) {
    .typeahead-wrap {
      .form-group {
        border-radius: 6px;
        button {
          display: none!important;
        }  
      }      
    }    
  } 
  .form-control {
    font-size: 25px;
    line-height: 34px;
    border-radius: 8px;
  //  max-width: 450px;
    border: solid 1px #000000;
    //width: 88%;
    @media (max-width: 991px) {
      border-radius: 6px;
      height: 48px;      
      line-height: 48px;
      //font-size: 32px;
    }
  }
  @media (max-width: 991px) {
    .not-in-list {
      top: -15px;
    }
  }
  .autocomplete-results {
    max-width: 450px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.3);
    z-index: 9;
    border: 1px solid #eeeeee;
    max-height: 330px;
    overflow: auto;
    text-align: right;
    right: 0;    
    width: 100%;
    margin-top: 3px;
    @media (max-width: 991px) {
      top: 45px;
      border-radius: 6px!important;
      box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.2);
      padding-right: 9px!important;
      padding-left: 9px!important;
    }
  } 
  .autocomplete-result {
    cursor: pointer;
    &:focus {
      border: 1px solid red;
    }
    @media (max-width: 991px) {
      border-bottom: 1px solid #e3e3e3;
      font-size: 32px;
    }
  }
  .autocomplete-result.is-active {
    background-color: #d6ecff !important; /* important to override hover color */
    @media (max-width: 991px) {
      background-color: transparent!important;    
    }    
    &::after {
      content: '\f00c';
      font-family:'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 14px;
      position:absolute;
      left: 0;
      top: 8px;
      @media (max-width: 991px) {
        top: 17px;
      }
      left: 10px;
    }
  }
  .autocomplete-result:hover {
    background-color: #f6f6f6;
  }
  li {
    font-size: 22px;
  }
</style>
<style lang="scss">
  .typeahead {
    .spinner-wrap {
      width: 30px;
      left: 30px;
      .v-spinner .v-clip {
        width: 25px;
        height: 25px;
        margin-top: 8px;      
      }
    }
  }
</style>